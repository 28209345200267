var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',[_c('header',[_c('h2',[_vm._v("Claim a Gift")]),_c('button',{staticClass:"close",on:{"click":_vm.close}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close modal window")])])]),_c('main',_vm._l((_vm.rewards),function(gift_row,index){return _c('div',{key:`gift_row_${index}`,staticClass:"gift-row"},[_c('div',{staticClass:"gift-index-tag"},[_c('span',[_vm._v("Gift #"+_vm._s(index + 1))]),(
            gift_row.length > 1 &&
              _vm.to_claim[index] &&
              _vm.to_claim[index].reward.type === 'CUSTOM_REWARD'
          )?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$delete(_vm.to_claim, index)}}},[_vm._v("(change gift)")]):_vm._e()]),(
          _vm.to_claim[index] && _vm.to_claim[index].reward.type === 'CUSTOM_REWARD'
        )?_c('div',[_c('claim-gift-product',{attrs:{"product":_vm.to_claim[index].reward},on:{"input":function($event){return _vm.$set(_vm.to_claim[index], 'variant', $event)}}})],1):[_c('p',[_vm._v(" This gift has multiple options available, choose which one you'd like to claim. ")]),_c('ul',_vm._l((gift_row),function(gift_opt,gift_index){return _c('li',{key:`gift_opt_${index}_${gift_index}`},[(gift_opt.type === 'CUSTOM_REWARD')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.selectGiftOpt(gift_row, index, gift_opt)}}},[_c('div',{staticClass:"thumbnail",style:({
                  'background-image': `url('${_vm.$catalogThumbnail(
                    gift_opt.reward.images[0]
                  )}')`,
                })}),_c('h5',[_vm._v(_vm._s(gift_opt.reward.name))])]):(gift_opt.type === 'POINTS')?_c('a',{class:[
                {
                  active: _vm.to_claim[index]
                    ? _vm.to_claim[index].reward.type === 'POINTS'
                    : false,
                },
              ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.selectGiftOpt(gift_row, index, gift_opt)}}},[_c('div',{staticClass:"points"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$formatPoints(gift_opt.points, true)))]),_c('span',[_vm._v(_vm._s(_vm.pointsName))])])])]):_vm._e()])}),0)]],2)}),0),_c('footer',[(!_vm.is_only_points)?_c('div',{staticClass:"ship-to"},[_c('div',{staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-toggle",style:({ color: this.addresses.length === 0 ? '#bf1717' : '' }),attrs:{"href":"#","id":"shipToDropdownLink","data-toggle":"dropdown"}},[_vm._v(_vm._s(_vm.selected_addr ? `Ship to: ${_vm.selected_addr.street1}` : _vm.addresses.length > 0 ? `Select shipping address...` : `Add new shipping address...`))]),_c('div',{staticClass:"dropdown-menu",on:{"click":function($event){$event.preventDefault();return _vm.changeAddress.apply(null, arguments)}}},[_vm._l((_vm.addresses),function(addr){return _c('a',{key:`addr_${addr.id}`,staticClass:"dropdown-item",attrs:{"href":"#","data-value":addr.id}},[_vm._v(_vm._s(addr.street1))])}),(_vm.addresses.length > 0)?_c('div',{staticClass:"dropdown-divider"}):_vm._e(),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-value":"manage_addresses"}},[_vm._v("Manage addresses")])],2)])]):_vm._e(),_c('button',{staticClass:"btn btn-green btn-sm",class:{ loading: _vm.loading },attrs:{"disabled":!_vm.can_claim || _vm.loading},on:{"click":_vm.claimGift}},[_vm._v(" Claim gift ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }