<template>
  <aside>
    <header>
      <h2>Claim a Gift</h2>
      <button class="close" @click="close">
        <span class="sr-only">Close modal window</span>
      </button>
    </header>

    <main>
      <div
        class="gift-row"
        v-for="(gift_row, index) in rewards"
        :key="`gift_row_${index}`"
      >
        <div class="gift-index-tag">
          <span>Gift #{{ index + 1 }}</span>
          <a
            v-if="
              gift_row.length > 1 &&
                to_claim[index] &&
                to_claim[index].reward.type === 'CUSTOM_REWARD'
            "
            href="#"
            @click.prevent="$delete(to_claim, index)"
            >(change gift)</a
          >
        </div>
        <div
          v-if="
            to_claim[index] && to_claim[index].reward.type === 'CUSTOM_REWARD'
          "
        >
          <claim-gift-product
            :product="to_claim[index].reward"
            @input="$set(to_claim[index], 'variant', $event)"
          ></claim-gift-product>
        </div>
        <template v-else>
          <p>
            This gift has multiple options available, choose which one you'd
            like to claim.
          </p>
          <ul>
            <li
              v-for="(gift_opt, gift_index) in gift_row"
              :key="`gift_opt_${index}_${gift_index}`"
            >
              <a
                href="#"
                @click.prevent="selectGiftOpt(gift_row, index, gift_opt)"
                v-if="gift_opt.type === 'CUSTOM_REWARD'"
              >
                <div
                  class="thumbnail"
                  :style="{
                    'background-image': `url('${$catalogThumbnail(
                      gift_opt.reward.images[0]
                    )}')`,
                  }"
                ></div>
                <h5>{{ gift_opt.reward.name }}</h5>
              </a>

              <a
                href="#"
                :class="[
                  {
                    active: to_claim[index]
                      ? to_claim[index].reward.type === 'POINTS'
                      : false,
                  },
                ]"
                @click.prevent="selectGiftOpt(gift_row, index, gift_opt)"
                v-else-if="gift_opt.type === 'POINTS'"
              >
                <div class="points">
                  <div>
                    <strong>{{ $formatPoints(gift_opt.points, true) }}</strong
                    ><span>{{ pointsName }}</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </template>
      </div>
    </main>

    <footer>
      <div class="ship-to" v-if="!is_only_points">
        <div class="dropdown">
          <a
            href="#"
            id="shipToDropdownLink"
            :style="{ color: this.addresses.length === 0 ? '#bf1717' : '' }"
            class="dropdown-toggle"
            data-toggle="dropdown"
            >{{
              selected_addr
                ? `Ship to: ${selected_addr.street1}`
                : addresses.length > 0
                ? `Select shipping address...`
                : `Add new shipping address...`
            }}</a
          >

          <div class="dropdown-menu" @click.prevent="changeAddress">
            <a
              class="dropdown-item"
              href="#"
              v-for="addr in addresses"
              :data-value="addr.id"
              :key="`addr_${addr.id}`"
              >{{ addr.street1 }}</a
            >
            <div class="dropdown-divider" v-if="addresses.length > 0"></div>
            <a class="dropdown-item" href="#" data-value="manage_addresses"
              >Manage addresses</a
            >
          </div>
        </div>
      </div>

      <button
        class="btn btn-green btn-sm"
        :class="{ loading: loading }"
        :disabled="!can_claim || loading"
        @click="claimGift"
      >
        Claim gift
      </button>
    </footer>
  </aside>
</template>

<script>
import ClaimGiftProduct from '@/components/RewardsPortal/ClaimGiftProduct';
export default {
  components: {
    ClaimGiftProduct,
  },
  props: ['data'],
  computed: {
    rewards() {
      return this.data.earned_award.rewards;
    },
    team() {
      return this.$store.state.team;
    },
    pointsName() {
      return _.capitalize(this.team.points_name);
    },
    is_only_points() {
      return _.every(
        Object.entries(this.to_claim),
        ([key, val]) => val.reward.type === 'POINTS'
      );
    },
    can_claim() {
      if (!this.is_only_points && this.selected_addr === null) return false;
      if (Object.keys(this.to_claim).length !== this.rewards.length)
        return false;
      return _.every(
        Object.entries(this.to_claim),
        ([key, val]) =>
          (val.variant !== null && val.variant !== false) ||
          val.reward.type !== 'CUSTOM_REWARD'
      );
    },
    addresses() {
      return this.$store.state.user.addresses;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  data() {
    return {
      to_claim: {},
      selected_addr: null,
    };
  },
  mounted() {
    this.selected_addr = _.find(this.addresses, { default: true }) || null;
    this.initGifts();
  },
  methods: {
    initGifts() {
      this.rewards.forEach((row, index) => {
        if (row.length === 1) {
          this.selectGiftOpt(null, index, row[0]);
        }
      });
    },
    selectGiftOpt(gift_row, index, gift_opt) {
      this.$set(this.to_claim, index, {
        variant: false,
        reward: gift_opt,
      });
    },
    close() {
      this.$emit('done');
    },
    async claimGift() {
      this.$store.commit('loading', true);

      Object.entries(this.to_claim).forEach(([index, claim]) => {
        claim.claim_id = this.$rand();
      });

      const resp = await this.$api.RewardPortal.claim_gift(
        this.data.earned_award.id,
        Object.keys(this.to_claim)
          .sort()
          .reduce((agg, val) => {
            agg.push(
              this.to_claim[val].reward.type === 'CUSTOM_REWARD'
                ? {
                    claim_id: this.to_claim[val].claim_id,
                    reward_id: this.to_claim[val].reward.reward.id,
                  }
                : 'POINTS'
            );
            return agg;
          }, []),
        Object.values(this.to_claim).reduce((agg, val) => {
          if (val.reward.type !== 'CUSTOM_REWARD') return agg;

          const orderParams = {
            addr_id: this.selected_addr.id,
          };

          if (val.variant.no_variant !== true) {
            orderParams.variant_id = val.variant.id;
            orderParams.variant_name = val.variant.name;
          }

          agg[`claim_${val.claim_id}`] = orderParams;

          return agg;
        }, {})
      );

      const points_awarded = Object.values(this.to_claim)
        .filter((opt) => opt.reward.type === 'POINTS')
        .reduce((agg, val) => {
          agg += val.reward.points;
          return agg;
        }, 0);

      if (points_awarded > 0) {
        this.$store.dispatch(
          'adjustRedeemablePoints',
          Math.abs(points_awarded)
        );
      }

      this.$store.commit('loading', false);
      this.$toast.success('Your gift has been claimed.');
      this.$emit('done', 'CLAIMED_GIFT');
    },
    addAddress() {
      this.$root.$emit(
        'openModal',
        'RewardsPortal/ShippingAddresses',
        {},
        (result) => {
          this.selected_addr = _.find(this.addresses, { default: true });
        }
      );
    },
    changeAddress(e) {
      const elem = e.target || e.srcElement;
      const value = elem.getAttribute('data-value');

      if (value === 'manage_addresses') {
        this.addAddress();
      } else {
        this.selected_addr = _.find(this.addresses, { id: value });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ship-to {
  margin: 0 0 15px;
  @include media-breakpoint-up(xl) {
    margin: 0 15px 0 0;
  }

  .dropdown-toggle {
    font-size: 14px;
    color: $muted-text;

    &:after {
      vertical-align: 1px;
    }
  }
}

.gift-row {
  border-radius: 10px;
  box-shadow: rgba($body-color, 0.05) 0 5px 10px,
    rgba($body-color, 0.05) 0 0 0 1px;
  padding: 15px;

  .gift-index-tag {
    display: flex;
    margin: 0 0 15px;
    align-items: center;

    > span {
      border-radius: 100em;
      background: $light;
      font-size: 12px;
      font-weight: 700;

      padding: 3px 8px;
      display: block;
      flex: 0 0 auto;
      white-space: nowrap;
    }

    > a {
      font-size: 12px;
      font-weight: 700;
      margin: 0 0 0 10px;
      display: block;
    }
  }

  + .gift-row {
    margin-top: 15px;
  }

  ul {
    display: flex;
    padding: 0;
    margin: 0 -10px;
    align-items: flex-start;

    > li {
      display: block;
      flex: 0 0 (100%/5);
      width: 100%/4;
      padding: 0 10px;
      height: auto;

      > a {
        color: $body-color;
        text-decoration: none;

        .thumbnail {
          display: block;
          padding: 0 0 100%;
          background-size: cover;
          background-position: center center;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: rgba($body-color, 0.05) 0 0 0 1px;
          transition: box-shadow 0.2s $curve, transform 0.2s $curve;
          background-color: #fff;
        }

        .points {
          padding: 0 0 100%;
          background-size: cover;
          background-position: center center;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: rgba($body-color, 0.05) 0 0 0 1px;
          transition: box-shadow 0.2s $curve, transform 0.2s $curve;
          background-color: #fff;
          position: relative;

          > div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            > strong {
              font-size: 18px;
            }
          }
        }

        &.active {
          .points {
            background: $green;
            color: #fff;
          }
        }

        h5 {
          font-size: 14px;
          margin: 10px 0 0;
          text-align: center;
          font-weight: 700;
        }

        &:hover {
          .thumbnail {
            box-shadow: $primary 0 0 0 1px;
          }
          .points {
            box-shadow: $primary 0 0 0 1px;
          }
        }
      }
    }
  }
}
</style>
